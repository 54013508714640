import './App.css';
import { MaintenanceScreen } from './pages/maintenance';

function App() {
  return (
    <div className="App">
      <MaintenanceScreen />
    </div>
  );
}

export default App;
