import React from 'react'
import logo from '../assets/logo_cercle_ouvert_asbl.png';
import '../styles/maintenanceScreen.scss'
import { BsYoutube} from 'react-icons/bs'
import {AiOutlineInstagram, AiFillFacebook} from 'react-icons/ai'

export const MaintenanceScreen = () => {
  return (
    <div className='maintenance-screen'>
      <img width={250} src={logo} alt="" />
      <small>
        <small className='title'>
              Nous sommes en maintenance.
        </small>
              Telle une page vierge attendant l'encre du génie, notre site entre dans une phase de réinvention
              dédiée à l'exploration et à l'expression de la beauté littéraire sous toutes ses facettes.
        </small>
        <small>
              Nous suspendons donc temporairement notre portail, et partons d'une feuille blanche avec une plume qui se renouvelle
              pour mieux servir notre amour commun pour la promotion de la littérature.
              Vos observations perspicaces, nous guident dans cette entreprise délicate.
        </small>
        <small>Continuez à suivre nos activités sur nos reseaux sociaux</small>
        <small className='social'>
            <a href="https://www.facebook.com/cercle.ouvert.liege"><AiFillFacebook className='fBbleu' /></a>
            <a href="https://www.instagram.com/cercleouvert/"><AiOutlineInstagram className='IGPurple' /></a>
            <a href="https://www.youtube.com/@cercleouvert7710"><BsYoutube className='YTred'/></a>
        </small>
    </div>
  )
}
